import { MDBCol, MDBRow } from 'mdbreact';

import React from 'react';
import Stars from './stars';

const TopCenter = (props) => {

    const post = props.post

    return (
        <MDBRow className="my-2 mx-auto">
            <MDBCol xs="12" sm="12" md="12" lg="12" className="col-12 p-0 h-100 my-auto">
                <h1 className="pagetitleCenters">
                    {post.title} - Ratings
                </h1>
                <Stars 
                    center={props.location.toLowerCase()} 
                    allPracticemaxRatings={props.allPracticemaxRatings}   
                    extValues={true} />
                <p>{props.address}</p>
            </MDBCol>
        </MDBRow>
    )
}

export default TopCenter