import { MDBCol, MDBContainer, MDBRow } from 'mdbreact';

import BreadcrumbMenu from '../../../components/menus/centeratingsbcmenu';
import CClistScript from '../../../components/centers/closercenters/cclistscript';
import CloserCenters from '../../../components/centers/closercenters/cclist';
import Layout from '../../../components/layout';
import Ratings from '../../../components/centers/ratings/centeratings';
import React from 'react';
import RightBox from '../../../components/menus/right_box';
import SeoCenters from '../../../components/centers/seocenters';
import TopCenter from '../../../components/centers/ratings/topcenteratings';
import { graphql } from "gatsby";

let gmdv = require(`../../../ghc_config`);

const Detail = ({ data, location }) => {

  const post = data.nodeCenter
  const allPracticemaxRatings = data.allPracticemaxRatings
  const allPracticemaxComments = data.allPracticemaxComments
  let loc= location.pathname.replace('ratings', '')

  //  Start Position for closer Centers
  let startPosition = {}
  if (post.field_latitude && post.field_longitude) {
    startPosition = {lat: Number(post.field_latitude), lng: Number(post.field_longitude)}
  } 
  
  /*  google destination https://developers.google.com/maps/documentation/urls/guide */
  let address = `${post.field_address_fl.trim()}, `
  address += `${post.field_city}, ${gmdv.selectState(post.field_state).spa} ${post.field_zip}`
  let destination = `${post.title}, ${address}`
  let dirGoogleLink =  `https://www.google.com/maps/dir/?api=1`
  dirGoogleLink += `&destination=${encodeURI(destination)}`
  if (post.field_google_place_id) {dirGoogleLink += `&destination_place_id=${post.field_google_place_id}`}
  
  return (
    <Layout>
      <SeoCenters post={post} cat="ratings"/>
      <main>
        <MDBContainer tag="section" id="maincontent">
          <MDBRow center>
            <MDBCol  xs="12" md="9" lg="9" className="col-12 contentLeft">
              <TopCenter 
                post={post} 
                location={loc} 
                allPracticemaxRatings={allPracticemaxRatings}
                address={address} />
              <CClistScript 
                startPosition={startPosition}
                allPracticemaxRatings={allPracticemaxRatings} />
              <BreadcrumbMenu title={post.title} alias={post.path.alias}/>
              <Ratings 
                center={loc} 
                center={loc} 
                allPracticemaxRatings={allPracticemaxRatings}
                allPracticemaxComments={allPracticemaxComments} />     
            </MDBCol>
            <MDBCol xs="12" md="3" lg="3" className="col-12 rightbox">
              <RightBox />
              {Object.keys(startPosition).length > 0 &&
              <CloserCenters 
                startPosition={startPosition}
                allPracticemaxRatings={allPracticemaxRatings} />}
            </MDBCol>
          </MDBRow>
        </MDBContainer>
      </main>
    </Layout>
  )
}

export const query = graphql`
  query($id: String! $pAlias: String!) {
    nodeCenter(id: { eq: $id }) {
      id
      title
      field_address_fl
      field_city
      field_state
      field_zip
      field_telephone_number
      field_fax_number
      body {
        value
      }
      created
      field_outcomes {
        value
      }
      path {
        alias
      }
      field_google_place_id
      field_latitude
      field_longitude
      relationships{
        field_services {
          id
          name
          field_featured
          field_service_page_link
          path {
            alias
          }
        }
      }
    }
    linkedItems: allTaxonomyTermServices(filter: {field_service_page_link: {ne: null}}, sort: {fields: name}) {
      edges {
        node {
          id
          name
          field_service_page_link
        }
      }
    }

    #centerStarsRatings
    allPracticemaxRatings {
      totalCount
      edges {
        node {
          shortName
          id
          medicalOrganizationEntity {
            name
            aggregateRating {
              _type
              bestRating
              ratingCount
              ratingValue
              worstRating
            }
          }
        }
      }
    }

    #Comments
    allPracticemaxComments(filter: {internal: {content: {eq: $pAlias}}}) {
      totalCount
      edges {
        node {
          ratingValue
          reviewDate
          authorType
          reviews {
            question
            answer
          }
          internal {
            content
          }
        }
      }
    }
  }
`;

export default Detail